import React, { Component } from 'react';
import {
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    Navbar,
    NavbarBrand,
    Button,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
//import photo from './../../assets/images/Booongo_vikings_winter.jpg'
import { gamesAPI } from '../../api';
//import { GamesGroupCard } from '../GamesGroup/GamesGroupGrid';


export default class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            inputValue: "",
            foundedGames: []
        };
        this.ref = React.createRef(null)
        this.toggle = this.toggle.bind(this)
        this.setValueOnHandleChange = this.setValueOnHandleChange.bind(this)
    }

    toggle = () => this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));

    setValueOnHandleChange = async e => {
        const value = e.target.value
        this.setState({ inputValue: value })

        if (value.length < 2) {
            this.setState({ foundedGames: [], dropdownOpen: false })
            return
        }

        if (value.length > 1) {
            let response = await gamesAPI.searchGames(value)
            if (response.length !== 0) {
                this.setState({ foundedGames: response, dropdownOpen: true })

            } else {
                this.setState({ foundedGames: [], dropdownOpen: false })
            }
        }
    }

    render() {
        return (
            <>
                <header className={"navbar__header"}>
                    <Container>
                        <div className='navbar__header-inner'>
                            <div className="navbar__header-phone">
                                <img className={'navbar__header-icons'} src='https://cdn.pay2play.az/img/phone.webp' alt="phone" />
                                <a href="tel:+994515932063">(+994) 51 593 20 63</a>
                            </div>
                            <div className={"navbar__header-email"}>
                                <img className={'navbar__header-icons'} src='https://cdn.pay2play.az/img/email.webp' alt='email' />
                                <a href="mailto: support@pay2play.az">support@pay2play.az</a>
                            </div>
                            <div className={"navbar__header-social"}>
                                <SocialIcon className={'navbar__header-icons'} network="facebook" url="https://www.facebook.com/pay2playaz" target="_blank" fgColor="white" />
                                <SocialIcon className={'navbar__header-icons'} network="instagram" url="https://www.instagram.com/pay2play.az/" target="_blank" />
                                <SocialIcon className={'navbar__header-icons'} network="whatsapp" url="https://wa.me/994515932063" target="_blank"  />
                            </div>
                        </div>
                    </Container>
                </header>
                <div>
                    <Navbar className="navbar navbar-expand mb-0" light>
                        <Container>
                            <NavbarBrand className='text-light w-25 text-left' tag={Link} to="/">
                                <img height="55" src='https://cdn.pay2play.az/img/logo.webp' alt="" />
                            </NavbarBrand>

                            <Dropdown className='w-75 m-0 p-0' isOpen={this.state.dropdownOpen} inNavbar={true} toggle={this.toggle}>
                                <DropdownToggle
                                    tag="div"
                                    data-toggle="dropdown"
                                    aria-expanded={this.state.dropdownOpen}
                                >
                                    <InputGroup className='border-rounded w-100' autoComplete="off">
                                        <Input
                                            id="navbar__search"
                                            ref={this.ref}
                                            placeholder="Oyunu tapmaq üçün adını daxil edin"
                                            value={this.state.inputValue}
                                            onChange={this.setValueOnHandleChange}
                                        />

                                        <InputGroupAddon addonType="append">
                                            <Button className='bg-light text-dark border-light' disabled={true} onClick={e => e.preventDefault()}>
                                                <i aria-disabled={true} className="fas fa-search" />
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </DropdownToggle>

                                <DropdownMenu
                                    onClick={() => {
                                        //this.toggle()
                                        this.setState({ inputValue: "" })
                                    }}
                                    className='dropdown__menu mt-2 p-3'
                                >
                                    {
                                        this.state.foundedGames.length
                                            ?
                                            this.state.foundedGames.map(element => {
                                                return (
                                                    <Link className="navbar__foundedgames-link" to={`/games-group/${element.serviceId}`} key={element.name}>
                                                        {element.name}
                                                    </Link>
                                                )
                                            })

                                            : null
                                    }
                                    <Row className='d-flex justify-content-around overflow-auto' style={{ maxHeight: '300px', padding: "1rem" }}>
                                        {
                                            this.state.foundedGames.map(element => {
                                                return <span
                                                    //image={photo}
                                                    key={element.name}
                                                    {...element}
                                                />
                                            })
                                        }
                                    </Row>
                                </DropdownMenu>

                            </Dropdown>
                        </Container>
                    </Navbar>
                </div>
            </>
        );
    }
}