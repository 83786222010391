import { combineReducers } from "redux";
import games from './gamesReducer';
import app from './appReducer';
import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
    games,
    app,
    toastr
});
