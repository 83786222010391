import React from 'react'

export default function ({ children }) {
    return (
        <div className='content'>
            <div className="content__inner">
                {children}
            </div>
        </div>
    )
}