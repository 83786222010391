import * as types from './../constants';

let initialState = {
    didStoreFilled: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.FILL_STORE:
            return { ...state, didStoreFilled: action.payload }
        default:
            return state
    }
};