import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Navbar from '../components/navbar/index';
import Wrapper from '../components/Wrapper';
import Content from '../components/content';
import Footer from './../components/footer';

export class Layout extends Component {
    render() {
        return (
            <Wrapper>
                <Navbar />
                <Content>
                    <Container>
                        {this.props.children}
                    </Container>
                    <Footer />
                </Content>
            </Wrapper>
        );
    }
}