import React from 'react';
import { connect } from 'react-redux';
import GamesGroupGrid from './GamesGroupGrid';

const GamesGroup = ({ gamesData }) => {
    return (
        <React.Fragment>
            <GamesGroupGrid games={gamesData} />
        </React.Fragment>
    )
}

let mapStateToProps = state => {
    return {
        gamesData: state.games.gamesData
    }
}

export default connect(mapStateToProps, null)(GamesGroup);
