import React, { useEffect } from 'react';
import ReduxToastr from "react-redux-toastr";
import { connect } from 'react-redux';
import { Route } from 'react-router';
import Preloader from './components/Preloader';
import { Layout } from './Layouts/Layout';
import HomePage from './pages/HomePage/index';
import PaymentPage from './pages/PaymentPage';
import { fillStoreThunk } from './redux/actions/appAction';

const App = ({ didStoreFilled, fillStoreThunk }) => {
    useEffect(() => {
        fillStoreThunk(true)
    }, [fillStoreThunk])
    return (
        didStoreFilled
            ?
            <Layout>
                <Route exact path='/' component={HomePage} />
                <Route path='/games-group/:serviceId' render={() => <PaymentPage />} />
                <ReduxToastr
                    timeOut={5000}
                    newestOnTop={true}
                    position="top-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    closeOnToastrClick
                />
            </Layout>
            :
            <Preloader />
    )
}

let mapStateToProps = state => {
    return {
        didStoreFilled: state.app.didStoreFilled
    }
}

export default connect(mapStateToProps, { fillStoreThunk })(App);