import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
//import photo from './../../assets/images/Booongo_vikings_winter.jpg';
import { gamesAPI, userAPI, walletAPI } from '../../api';
import { Spinner } from 'reactstrap';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Input } from 'reactstrap';
//import { render } from 'react-dom';



const PaymentPage = ({ gamesData, match }) => {

    let { accountText, attributes, maxSum, name, serviceId, accountRegex, attributesRegex, hasChild } = gamesData.find(el => el.serviceId === +match.params.serviceId);
    let [isFetching, setIsFetching] = useState(false);
    let [children, setChildren] = useState([]);
    let ref = useRef({ maxSum, name });
    let [confirmationForm, setConfirmationForm] = useState(initialConfirmValues)
    let [isModalOpen, setIsModalOpen] = useState(false)
    let [isModalOpen2, setIsModalOpen2] = useState(false)
    let [selectedParent, setselectedParent] = useState('')
    let [serviceNotes, setServiceNotes] = useState('')
    let [paymentMethodState, setPaymentMethodState] = useState(0)

    const paymentMethod = [{ id: 1, name: 'Kredit kart' }, { id: 2, name: 'Pay2Play cuzdan' }]



    let [formValues, setFormValues] = useState({
        accountText: "",
        attributeValues: "",
        walletAccountText: "",
        walletPasswordText: "",
        miqdar: "",
        selection: name,
        miqdarQuotient: "1",
        qiymat: maxSum,
        gameName: name,
        serviceId
    });

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(() => {
        setFormValues(prevState => ({ ...prevState, gameName: name, qiymat: maxSum, selection: name, serviceId }))
    }, [name, maxSum, serviceId])

    useEffect(() => {
        if (hasChild) {
            ref.current.name = children[0]?.name;
            setFormValues(prevState => ({ ...prevState, qiymat: children[0]?.maxSum, serviceId: children[0]?.serviceId }))
        }
    }, [hasChild, children])

    let formOnHandleChange = (e, type) => {
        let name = type ? type : e.target.name;
        let value = e.value ? e.value : e.target.value;
        switch (name) {
            case 'miqdar':
                if ((value.split(".").length === 1 || value.split('.')[1].length <= 2) && value.split('.')[0][0] !== "0") {
                    setFormValues({ ...formValues, miqdar: value })
                }
                break
            case 'walletAccountText':
                setFormValues({ ...formValues, walletAccountText: value })
                break
            case 'walletPasswordText':
                setFormValues({ ...formValues, walletPasswordText: value })
                break
            case "miqdarQuotient":
                if (/^\d*$/.test(value) && value !== "0" && value.length < 10) {

                    let findGame = children.find(element => element.name === `${selectedParent}`);
                    if (findGame === undefined) {
                        findGame = children.find(element => element.name === children[0].name);
                    }
                    ref.current.maxSum = findGame.maxSum;
                    ref.current.name = findGame.name;
                    setFormValues(
                        {
                            ...formValues, [name]: value, qiymat: value ? value * ref.current['maxSum'] : ref.current['maxSum']
                        })
                }
                break
            case 'selection':
                let findGame = children.find(element => element.name === value);
                ref.current = { name: findGame['name'], maxSum: findGame['maxSum'] };
                setselectedParent(ref.current.name);
                setFormValues({
                    ...formValues,
                    [name]: value,
                    gameName: findGame.name,
                    serviceId: findGame.serviceId,
                    qiymat: formValues['miqdarQuotient'] ? (findGame['maxSum'] * formValues['miqdarQuotient']) : findGame['maxSum'],
                })
                break
            default:
                setFormValues({ ...formValues, [name]: value, })
        }

    };

    let onHandleSubmit = async e => {
        let { accountText, attributeValues, serviceId, miqdarQuotient, miqdar, qiymat, walletAccountText, walletPasswordText } = formValues
        e.preventDefault()

        if (!(new RegExp(accountRegex).test(accountText))) {
            toastr.error(`${accountText} səhfdir.`)
        }

        if (attributesRegex && (!attributeValues || !(new RegExp(attributesRegex).test(attributes)))) {
            toastr.error(`${attributes.split(';')[1]} səhfdir.`)
            return;
        }

        if (hasChild) {
            if (miqdarQuotient < 1) {
                toastr.error("Miqdar Sehfdir.")
                return;
            }
        }
        else {
            if (miqdar < 1) {
                toastr.error("Məbləğ doğru deyil.")
                return;
            }
        }

        if (paymentMethodState === 2) {
            if (walletPasswordText.length < 6) {
                toastr.error('Şifrəni daxil edin')
                return;
            }

            if (!validateEmail(walletAccountText)) {
                toastr.error(`Cuzdan email-ı səhfdir.`)
                return;
            }
        }

        let response = await userAPI.takeResult(serviceId, (hasChild ? qiymat : miqdar), accountText, attributeValues, (miqdar ? miqdarQuotient : 0))
        setConfirmationForm(response)

        if (paymentMethodState === 1) {
            setIsModalOpen(true)
        } else if (paymentMethodState === 2) {
            setIsModalOpen2(true)
        }
    };

    let pay2 = async e => {
        let { accountText, attributeValues, serviceId, miqdarQuotient, miqdar, qiymat, walletAccountText, walletPasswordText } = formValues
        e.preventDefault()

        let response = await walletAPI.pay(walletAccountText, walletPasswordText, serviceId, accountText, attributeValues, (hasChild ? qiymat : +miqdar));
        if (response.isOk) {
            setFormValues({ ...formValues, walletPasswordText: '' })
            toastr.success('Ödəniş uğurla tamamlandı.');
        }
        else {
            toastr.error(response.message)
        }

        setIsModalOpen2(false)
    };

    let onSendEmail = async e => {
        let { walletAccountText } = formValues

        if (!validateEmail(walletAccountText)) {
            toastr.error(`Cuzdan email-ı səhfdir.`)
            return;
        }

        let response = await walletAPI.sendEmail(walletAccountText)
        if (response.isOk) {
            toastr.success('Email göndərildi')
        }
        else {
            toastr.error(response.message);
        }
    };

    let takeSelectionItems = serviceId => {
        setIsFetching(true)
        gamesAPI.takeChildren(serviceId)
            .then(value => setChildren(value))
            .then(() => setIsFetching(false))
    };

    useEffect(() => {
        document.querySelector('.content').scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (hasChild) takeSelectionItems(serviceId);
    }, [hasChild, serviceId]);

    useEffect(() => {
        gamesAPI.takeNotes(serviceId)
            .then(value => setServiceNotes(value.notes));
    }, [serviceId]);

    return (
        <div className="paymentpage__card">

            <div className="paymentpage__image">
                <img src={`https://cdn.pay2play.az/img/${serviceId}.webp`} alt="" />
            </div>

            <div className="paymentpage__info">
                <div className="paymentpage__name">{ref.current.name}</div>

                <form
                    className="paymentpage__form"
                    autoComplete='off'
                    onSubmit={onHandleSubmit}
                >
                    {
                        !hasChild
                            ?
                            < div className="paymentpage__item">
                                <label htmlFor="" className='paymentpage__label'>Məbləğ AZN</label>
                                <Input
                                    className='paymentpage__input'
                                    type="number"
                                    name='miqdar'
                                    value={formValues['miqdar']}
                                    onChange={formOnHandleChange}
                                />
                            </div>
                            :
                            <React.Fragment>
                                {
                                    isFetching
                                        ?
                                        <Spinner color='primary' />
                                        :
                                        <>
                                            <div className='paymentpage__cost'>
                                                Məbləğ AZN: <span style={{ color: "teal" }} >{formValues['qiymat'].toFixed(2)}</span>
                                            </div>

                                            <Select
                                                className='paymentpage__select'
                                                name='selection'
                                                onChange={e => formOnHandleChange(e, 'selection')}
                                                placeholder={children[0]?.name}
                                                //defaultInputValue={children[0]?.name}
                                                options={
                                                    children.map(element => (
                                                        {
                                                            value: element.name,
                                                            label: element.name
                                                        }

                                                    ))
                                                }
                                            />
                                        </>
                                }

                                <div className="paymentpage__item">
                                    <label className='paymentpage__label' htmlFor="paymentpage__input">Miqdar</label>
                                    <Input
                                        className='paymentpage__input'
                                        name={'miqdarQuotient'}
                                        id='paymentpage__input'
                                        type="number"
                                        onChange={formOnHandleChange}
                                        value={formValues['miqdarQuotient']}
                                    />
                                </div>



                            </React.Fragment>
                    }

                    <div className="paymentpage__item">
                        <label className="paymentpage__label" htmlFor="paymentpage__input">{accountText}</label>
                        <Input
                            className="paymentpage__input"
                            id='paymentpage__input'
                            name='accountText'
                            value={formValues['accountText']}
                            onChange={formOnHandleChange}
                        />

                    </div>

                    {attributes &&
                        <div className="paymentpage__item">
                            <label className="paymentpage__label" htmlFor="paymentpage__input">{attributes.split(';')[1]}</label>
                            <Input
                                className="paymentpage__input"
                                id='paymentpage__input'
                                name='attributeValues'
                                value={formValues['attributeValues']}
                                onChange={formOnHandleChange}
                            />

                        </div>
                    }

                    <div>
                        <label className="paymentpage__label" htmlFor="paymentMethod">Ödəniş üsulu</label>
                        <Select
                            className='paymentpage__select'
                            name='paymentMethod'
                            onChange={(e) => { setPaymentMethodState(e.value) }}
                            //defaultInputValue={children[0]?.name}
                            placeholder='Ödəniş üsulu seçin...'
                            options={
                                paymentMethod.map(element => (
                                    {
                                        value: element.id,
                                        label: element.name
                                    }
                                ))
                            }
                        />
                    </div>
                    {
                        paymentMethodState === 2 &&
                        <div>
                            <div className="paymentpage__item">

                                <label className="paymentpage__label" htmlFor="paymentpage__input">Cuzdan email</label>
                                <Input
                                    className="paymentpage__input"
                                    id='paymentpage__input'
                                    name='walletAccountText'
                                    onChange={formOnHandleChange}
                                    value={formValues['walletAccountText']}
                                />
                            </div>

                            <div className="paymentpage__item">
                                <button className="paymentpage__submit btn btn-primary" type='button' onClick={onSendEmail}>Şifrəni email-a göndər</button>
                            </div>
                            <div className="paymentpage__item">
                                <label className="paymentpage__label" htmlFor="paymentpage__input">Cuzdan şifrə</label>
                                <Input
                                    className="paymentpage__input"
                                    id='paymentpage__input'
                                    name='walletPasswordText'
                                    onChange={formOnHandleChange}
                                    value={formValues['walletPasswordText']}
                                />
                            </div>

                        </div>

                    }
                    {
                        paymentMethodState !== 0 &&
                        <div className="paymentpage__item">
                            <button className="paymentpage__submit btn btn-primary" type='submit'> Təsdiq et</button>
                        </div>
                    }

                </form>
                <div
                    className="paymentpage__modal"
                    style={{ display: isModalOpen ? "flex" : "none" }}
                >
                    <div
                        className="paymentpage__confirm-text w-50 text-center text-light mb-3"
                        style={{ fontSize: "1.5rem" }}
                    >
                        Məbləğ:     {!hasChild ? formValues.miqdar : formValues.qiymat} AZN
                    </div>
                    <br />
                    <div
                        className="paymentpage__confirm-text w-50 text-center text-light mb-3"
                        style={{ fontSize: "1.5rem" }}
                    >
                        Xidmət: {`${confirmationForm.desc}`}
                    </div>
                    <br />
                    <div
                        className="paymentpage__confirm-text w-50 text-center text-light mb-3 text-break"
                        style={{ fontSize: "1.5rem" }}
                    >
                        {/*Akkaunt:    {formValues.accountText}*/}
                    </div>

                    <form action="https://mpi.3dsecure.az/cgi-bin/cgi_link" method="POST" >
                        <input name="AMOUNT" value={confirmationForm.amount} type="hidden" />
                        <input name="CURRENCY" value={confirmationForm.currency} type="hidden" />
                        <input name="ORDER" value={confirmationForm.order} type="hidden" />
                        <input name="DESC" value={confirmationForm.desc} type="hidden" />
                        <input name="MERCH_NAME" value={confirmationForm.merch_Name} type="hidden" />
                        <input name="MERCH_URL" value={confirmationForm.merch_Url} type="hidden" />
                        <input name="TERMINAL" value={confirmationForm.terminal} type="hidden" />
                        <input name="EMAIL" value={confirmationForm.email} type="hidden" />
                        <input name="TRTYPE" value={confirmationForm.trtype} type="hidden" />
                        <input name="COUNTRY" value={confirmationForm.country} type="hidden" />
                        <input name="MERCH_GMT" value={confirmationForm.merch_Gmt} type="hidden" />
                        <input name="TIMESTAMP" value={confirmationForm.timestamp} type="hidden" />
                        <input name="NONCE" value={confirmationForm.nonce} type="hidden" />
                        <input name="BACKREF" value={confirmationForm.backref} type="hidden" />
                        <input name="P_SIGN" value={confirmationForm.p_sign} type="hidden" />

                        <input className="btn btn-primary" alt="Təsdiq et" type="submit" value="Təsdiq et" />
                    </form>
                    <button
                        type="button"
                        className='btn btn-danger mt-3'
                        onClick={(e) => {
                            e.preventDefault()
                            setIsModalOpen(false)
                        }}
                    >
                        Geri qayıt
                        </button>
                </div>

                <div
                    className="paymentpage__modal"
                    style={{ display: isModalOpen2 ? "flex" : "none" }}
                >
                    <div
                        className="paymentpage__confirm-text w-50 text-center text-light mb-3"
                        style={{ fontSize: "1.5rem" }}
                    >
                        Məbləğ:     {!hasChild ? formValues.miqdar : formValues.qiymat} AZN
                    </div>
                    <br />
                    <div
                        className="paymentpage__confirm-text w-50 text-center text-light mb-3"
                        style={{ fontSize: "1.5rem" }}
                    >
                        Xidmət: {`${confirmationForm.desc}`}
                    </div>
                    <br />
                    <div
                        className="paymentpage__confirm-text w-50 text-center text-light mb-3 text-break"
                        style={{ fontSize: "1.5rem" }}
                    >
                    </div>

                    <button type="button" className='btn btn-primary mt-3'
                        onClick={pay2}
                    >Təsdiq et</button>

                    <button type="button" className='btn btn-danger mt-3'
                        onClick={(e) => {
                            e.preventDefault()
                            setIsModalOpen2(false)
                        }}
                    >Geri qayıt</button>
                </div>
            </div>


            <div className="paymentpage__notes" dangerouslySetInnerHTML={{ __html: serviceNotes }} />
        </div >
    )
}

let mapStateToProps = state => {
    return {
        gamesData: state.games.gamesData
    }
}

export default withRouter(connect(mapStateToProps, null)(PaymentPage));

let initialConfirmValues = {
    amount: "",
    backref: "",
    country: "",
    currency: "",
    desc: "",
    email: "",
    merch_Gmt: "",
    merch_Name: "",
    merch_Url: "",
    nonce: "",
    order: "",
    p_sign: "",
    terminal: "",
    timestamp: "",
    trtype: "",
}