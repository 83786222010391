import React from 'react';

const Default = () => {
	return (
		<div className='footer'>
			<div className='container'>
				<div className='footer__inner'>
					<div className='footer__items'>
						<h2>Əlaqə</h2>
						<div className='footer__item'>
							Pay2Play MMC
						</div>
						<div className='footer__item'>
							Ünvan: Fəzail Bayramov 11/56
						</div>
						<div className='footer__item'>
							VÖEN: 1202630631
						</div>
						<div className='footer__item'>
							Telefon: <a href="tel:+994515932063">+994515932063</a>
						</div>
					</div>

					<div className='footer__items-center'>
						<div className='footer__item'>
							<a href="/website-rules.html" target="_blank">Saytın qaydaları</a>
						</div>
						<div className='footer__item'>
							<a href="/terms-conditions.html" target="_blank">İstifadəçi razılaşması</a>
						</div>
						<div className='footer__item'>
							<a href="/privacy-policy.html" target="_blank">Məxfilik siyasəti</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Default;