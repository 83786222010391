import { post } from "jquery"

let baseURL = process.env.REACT_APP_SERVER_URL;

export const gamesAPI = {
    takeAllGames: async () => {
        let response = await fetch(baseURL + 'data/services', {
            credentials: "include",
            headers: [
                ["Content-Type", "application/json"],
                ['Accept', 'application/json'],
                ["Access-Control-Allow-Origin", "*"]
            ]
        })
        let data = await response.json()
        return data
    },

    takeChildren: async pid => {
        let response = await fetch(baseURL + `data/services?pid=${pid}`)
        let data = await response.json()
        return data
    },

    searchGames: async name => {
        let response = await fetch(baseURL + `data/services?search=${name}`)
        let data = await response.json()
        return data
    },

    takeNotes: async serviceId => {
        let response = await fetch(baseURL + `data/serviceNotes?serviceId=${serviceId}`)
        let data = await response.json()
        return data
    }
}

export const userAPI = {
    takeResult: async (serviceId, amount, account, attributes, count) => {
        let response = await fetch(baseURL + `azericard/auth?serviceId=${serviceId}&amount=${amount}&account=${account}&count=${count}&attributeValues=${attributes}`)
        let data = await response.json()
        return data
    }
}

export const walletAPI = {
    sendEmail: async (account) => {
        let response = await fetch(baseURL + `wallet/sendEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ account: account })
        })
        let data = await response.json()
        return data
    },

    pay: async (walletAccount, walletPassword, serviceId, account, attributeValues, amount) => {
        let response = await fetch(baseURL + `wallet/pay`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                walletAccount,
                walletPassword,
                serviceId,
                account,
                attributeValues,
                amount
            })
        })
        let data = await response.json()
        return data
    }
}