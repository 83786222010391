import React from 'react';
import { Link } from 'react-router-dom';
//import photo from './../../assets/images/Booongo_vikings_winter.jpg';

export const GamesGroupCard = ({ serviceId, name }) => {
    return (
        <div className="games-group__card">
            <Link
                className="games-group__card-inner"
                to={`/games-group/${serviceId}`}
            >
                <img
                    className="games-group__card-image"
                    src={`https://cdn.pay2play.az/img/${serviceId}.webp`}
                    alt="here is card"
                />
                <div className="games-group__card-name">
                    {name}
                </div>
            </Link>
        </div>
    )
}

const GamesGroupGrid = ({ games }) => {
    return (
        <div>
            <div className="games-group__card-grid">
                {
                    games.map(el => {
                        return (
                            <GamesGroupCard
                                key={el.name}
                                name={el.name}
                                image={el.image}
                                serviceId={el.serviceId}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default GamesGroupGrid;