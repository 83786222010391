import { gamesAPI } from '../../api';
import * as types from './../constants';

export const setGamesData = games => ({ type: types.SET_GAMES, payload: games });

export const setGamesThunk = () => async dispatch => {
    try {
        let response = await gamesAPI.takeAllGames()
        dispatch(setGamesData(response))
    } catch (error) {
        console.error(error)
    }
};