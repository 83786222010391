import React from 'react'
import { Spinner } from 'reactstrap'

const Preloader = () => {
    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: 'fixed',
            top: 0,
            left: 0
        }}>
            <Spinner />
        </div>
    )
}

export default Preloader
