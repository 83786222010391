import * as types from './../constants';

let initialState = {
    gamesData: []
};


export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_GAMES:
            return { ...state, gamesData: action.payload }
        default:
            return state
    }
};